import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

function withPropType (obj) {
  obj.propType = PropTypes.oneOf(Object.values(obj))
  return obj
}

export const FormTypes = withPropType({
  PRODUCTION: 'production',
  STOPPAGE: 'stoppage',
  REALIZATION: 'realizationProduction',
  REALIZATION_SCRAP: 'realizationScrap',
  REALIZATION_STOPPAGE: 'realizationStoppage',
  REALIZATION_CHANGEOVER: 'realizationChangeover',
  REALIZATION_UNREPORTED: 'realizationUnreported',
  BREAKDOWN_START: 'breakdownStart',
  BREAKDOWN_FINISH: 'breakdownFinish'
})

export const Modes = withPropType({
  PLANNING: 'planning',
  REALIZATION: 'realization'
})

export const DivisionMessages = defineMessages({
  electrical: {
    id: 'workspace.form.divisions.electrical',
    defaultMessage: 'Electrical'
  },
  logistics: {
    id: 'workspace.form.divisions.logistics',
    defaultMessage: 'Logistics'
  },
  mechanical: {
    id: 'workspace.form.divisions.mechanical',
    defaultMessage: 'Mechanical'
  },
  quality: {
    id: 'workspace.form.divisions.quality',
    defaultMessage: 'Quality'
  },
  technical: {
    id: 'workspace.form.divisions.technical',
    defaultMessage: 'Technical'
  }
})
